<template>
	<div>
		<div class="rounded-md bg-yellow-50 p-4" v-if="error">
			<div class="flex">
				<div class="flex-shrink-0">
					<!-- Heroicon name: solid/exclamation -->
					<svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
						<path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
					</svg>
				</div>
				<div class="ml-3">
					<h3 class="text-sm font-medium text-yellow-800">
						No table found!
					</h3>
					<div class="mt-2 text-sm text-yellow-700">
						<p>
						The pasted textile text didn't contain a table. Please only paste the textile-formatted table.
						</p>
					</div>
				</div>
			</div>
		</div>
		<textarea v-model="textile" rows="7" class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
		<button @click="parseTextile" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2">Parse Textile</button>
		<div v-html="rendered" ref="content" class="hidden"></div>
	</div>
</template>

<script>
import textile from 'textile-js';
export default {
	name: 'Editor',
	data() {
		return {
			error: false,
			step: 1,
			textile: '|new column|',
			rendered: '',
			table: [['new column']]
		}
	},
	methods: {
		parseTextile() {
			this.rendered = textile(this.textile)
			this.$nextTick().then(() => {
				let tableElement = this.$refs.content.getElementsByTagName('table')[0]
				if (!tableElement) {
					this.error = true
					return
				}
				this.table = []
				tableElement.children[0].getElementsByTagName('tr').forEach(rowElement => {
					let row = []
					rowElement.children.forEach(cellElement => {
						row.push(cellElement.textContent.trim())
					})
					this.table.push(row)
				})
				this.$emit('parsed', this.table)
			})
		}
	}
}
</script>