<template>
	<li class="relative overflow-hidden lg:flex-1" @click="$emit('step', step)">
		<div class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0">
			<!-- Completed Step -->
			<a href="#" class="group" v-if="step < currentStep">
				<span class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto" aria-hidden="true"></span>
				<span class="px-6 py-5 flex items-start text-sm font-medium">
					<span class="flex-shrink-0">
						<span class="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full">
							<!-- Heroicon name: solid/check -->
							<svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
							</svg>
						</span>
					</span>
					<span class="mt-0.5 ml-4 min-w-0 flex flex-col">
						<span class="text-xs font-semibold tracking-wide uppercase">{{title}}</span>
						<span class="text-sm font-medium text-gray-500">{{description}}</span>
					</span>
				</span>
			</a>
			<a href="#" aria-current="step" v-if="step == currentStep">
				<span class="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto" aria-hidden="true"></span>
				<span class="px-6 py-5 flex items-start text-sm font-medium">
					<span class="flex-shrink-0">
						<span class="w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
							<span class="text-indigo-600">{{step}}</span>
						</span>
					</span>
					<span class="mt-0.5 ml-4 min-w-0 flex flex-col">
						<span class="text-xs font-semibold text-indigo-600 tracking-wide uppercase">{{title}}</span>
						<span class="text-sm font-medium text-gray-500">{{description}}</span>
					</span>
				</span>
			</a>
			<a href="#" class="group" v-if="step > currentStep">
				<span class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto" aria-hidden="true"></span>
				<span class="px-6 py-5 flex items-start text-sm font-medium">
					<span class="flex-shrink-0">
						<span class="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
							<span class="text-gray-500">{{step}}</span>
						</span>
					</span>
					<span class="mt-0.5 ml-4 min-w-0 flex flex-col">
						<span class="text-xs font-semibold text-gray-500 tracking-wide uppercase">{{title}}</span>
						<span class="text-sm font-medium text-gray-500">{{description}}</span>
					</span>
				</span>
			</a>
			<!-- Separator -->
			<div class="hidden absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true" v-if="separator">
				<svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
					<path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
				</svg>
			</div>
		</div>
	</li>
</template>

<script>
export default {
	props: ['title', 'description', 'step', 'currentStep', 'separator']
}
</script>